@tailwind base;
@tailwind components;
@tailwind utilities;

/* Starry background */
.starry-background {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: black;
  overflow: hidden;
}

.starry-background::before,
.starry-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background-image: radial-gradient(white 1px, transparent 1px), radial-gradient(white 1px, transparent 1px);
  background-size: 100px 100px, 50px 50px;
  opacity: 0.4;
}

@keyframes twinkling {
  0% { transform: translate(0, 0); }
  100% { transform: translate(-100%, -100%); }
}